@font-face {
  font-family: AvertaStd-Regular;
  src: url("../src/Assets/Fonts/AvertaStd-Regular.ttf");
}

@font-face {
  font-family: AvertaStd-Bold;
  src: url("../src/Assets/Fonts/AvertaStd-Bold.ttf");
}

@font-face {
  font-family: AvertaStd-Semibold;
  src: url("../src/Assets/Fonts/AvertaStd-Bold.ttf");
}
fieldset{
  border: none !important;
  outline: none !important;
}
.mui-focused .muioutlinedinput-notchedoutline{
  border:none !important
}
.MuiOutlinedInput-notchedOutline{ border: 0 }
:root {
  // background of btn , title of the table , hover icons in the sidebar,
  // active page name , hover page name
  //( to be changed the color in the sign in page )
  --primary: #c33e30;
  //color of the body background
  --bodybackgroundColor: #f7f7f7;
  // background color of the card , sidebar , navbar , modal , btn switch in the table like language ,
  --backgroundColor: #ffffff;
  // color of filter btn span in the table , color of title in the modal ,
  // title in the dashboard , color of active btn lang in the table,
  // text in the inputs like select , textarea , label of toggle switch status
  --color: rgba(0, 0, 0, 0.54);
  // placholder for the inputs , sidebar page name , title span in the modal for each input , icons color in the sidebar
  --disabletext: #b2b2b2;
  // Navbar drpdown color of text , small title of the first section in the dashboard ,
  // actions btn in the table , btn switch inactive in the table like languages
  --secondtext: #939393;
  // table text color like title of columns and data for each column
  --tabletextcolor: #4b4b4b;

  --customizerColor: #c41035;

  --modalBackground: #fff;
}

[data-theme="dark"] {
  // background of btn , title of the table , hover icons in the sidebar,
  // active page name , hover page name
  //( to be changed the color in the sign in page )
  --primary: #c33e30;
  //color of the body background
  --bodybackgroundColor: #232323;
  // background color of the card , sidebar , navbar , modal , btn switch in the table like language ,
  --backgroundColor: #474040a1;
  // color of filter btn span in the table , color of title in the modal ,
  // title in the dashboard , color of active btn lang in the table,
  // text in the inputs like select , textarea , label of toggle switch status
  --color: #ffffff;
  // placholder for the inputs , sidebar page name , title span in the modal for each input , icons color in the sidebar
  --disabletext: #b2b2b2;
  // Navbar drpdown color of text , small title of the first section in the dashboard ,
  // actions btn in the table , btn switch inactive in the table like languages
  --secondtext: #939393;
  // table text color like title of columns and data for each column
  --tabletextcolor: #4b4b4b;

  --customizerColor: #fff;
  --modalBackground: black;
}

body {
  margin: 0;
  padding: 0 !important;
  font-family: "AvertaStd-Regular", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #191820;
  text-align: left;
  background-color: var(--bodybackgroundColor, rgba(0, 0, 0, 0.04)) !important;
  overflow-y: auto !important;
  // direction: rtl;
}
header {
  padding: 0 !important;
}

html,
body {
  margin: 0;
  padding: 0 !important;
}

.end {
  display: flex;
  justify-content: end;
  align-items: center;
}

.start {
  display: flex;
  justify-content: start;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.endresponsive {
  display: flex;
  justify-content: end;
  align-items: center;
}

.centerresponsive {
  display: flex;
  justify-content: start;
  align-items: center;
}

.Top-spacing {
  margin-top: 20px;
  margin-bottom: 20px;
}

.MobileLogo-sidebar {
  position: absolute;
  left: -5px;
  width: 60px;
  @media (max-width: 700px) {
    left: 0px;
    width: 45px;
  }
}

.Logo-sidebar {
  width: 140px;
  padding: 0 10px;
}

.form-contol-nav {
  width: 220px !important;
  margin-top: -1% !important;
}

.DropdownNav-text {
  color: var(--stored-color, #c41035) !important;
  font-size: 0.9em !important;
  font-weight: normal !important;
}

.DropdownNav-text-gray {
  color: grey !important;
  font-size: 0.9em !important;
  font-weight: normal !important;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-width: 0px !important;
}

.grey {
  background: #f7f7f7;
}

.white {
  background: white;
}

.css-s5zwvx-MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within,
.css-s5zwvx-MuiDataGrid-root .MuiDataGrid-cell:focus-within {
  outline: none !important;
}

.dialog-grid-spacing {
  margin: 4px 0px !important;
}

.red-box {
  background-color: red;
  width: 40px;
  height: 40px;
  border-radius: 10px;
  cursor: pointer;
}

.purple-box {
  background-color: purple;
  width: 40px;
  height: 40px;
  border-radius: 10px;
  margin-left: 10px;
  cursor: pointer;
}

.pink-box {
  background-color: pink;
  width: 40px;
  height: 40px;
  border-radius: 10px;
  margin-left: 10px;
  cursor: pointer;
}

.grey-box {
  background-color: grey;
  width: 40px;
  height: 40px;
  border-radius: 10px;
  margin-left: 10px;
  cursor: pointer;
}

.green-box {
  background-color: green;
  width: 40px;
  height: 40px;
  border-radius: 10px;
  margin-left: 10px;
  cursor: pointer;
}

.blue-box {
  background-color: rgb(61, 61, 248);
  width: 40px;
  height: 40px;
  border-radius: 10px;
  margin-left: 10px;
  cursor: pointer;
}

.tick-icon {
  margin: 7px;
  color: white;
}

.setting-icon {
  font-size: 30px !important;
  position: fixed;
  top: 400px;
  right: 0;
  color: var(--setting-icon, #c41035);
  cursor: pointer;
  z-index: 1000000 !important;
}

.language-icon {
  position: absolute;
  top: 15px;
  margin-left: -5px;
}

.contained {
  background-color: var(--stored-color, #c41035) !important;
  border-radius: 32px !important;
  font-size: 14px !important;
  box-shadow: none !important;
  text-transform: inherit !important;
  color: white !important;
  padding: 7px 20px !important;
  // min-width: 110px!important;
  // min-width: 170px !important;
  color: white !important;

  &:hover {
    background-color: var(--stored-color, #c41035) !important;
    box-shadow: none !important;
  }

  @media (max-width: 1000px){
    padding: 5px 10px !important;
  }
}

.contained-roles {
  background-color: var(--stored-color, #c41035) !important;
  border-radius: 32px !important;
  font-size: 14px !important;
  box-shadow: none !important;
  text-transform: inherit !important;
  color: white !important;
  padding: 7px 20px !important;
  // min-width: 110px!important;
  // min-width: 170px !important;
  color: white !important;
  width: 170px !important;

  &:hover {
    background-color: var(--stored-color, #c41035) !important;
    box-shadow: none !important;
    width: 170px !important;
  }

  @media (max-width: 500px) {
    width: 130px !important;
    padding: 5px 10px !important;
    font-size: 12px !important;

  }
}

.contained-white-blue {
  background-color: #c41035 !important;
  width: "100%!important";
  border-radius: 32px !important;
  font-size: 14px !important;
  box-shadow: none !important;
  text-transform: inherit !important;
  color: white !important;
  padding: 7px 20px !important;
  // min-width: 110px!important;
  // min-width: 170px !important;
  color: white !important;

  &:hover {
    // background-color: #1483df !important;
    box-shadow: none !important;
  }
}
.MuiCheckbox-root.Mui-checked {
  color: #ff91a8d4 !important;
}
.Top-spacing-data {
  margin-top: 20px;
  margin-bottom: 20px;
  @media (max-width:1000px){
    margin-top: -25px;
  }
}

.outlined {
  border-radius: 17.6px !important;
  height: 35px !important;
  padding: 6px 24.5px 8.1px 23px !important;
  box-shadow: none !important;
  text-transform: inherit !important;
  background-color: white !important;
  color: #c41035 !important;
  border: 1px solid #c41035 !important;

  &:hover {
    background-color: #c41035 !important;
    color: white !important;

    .export-icon-style {
      color: white !important;
    }
  }
}
.MuiFormControl-root-MuiTextField-root {
  height: 35px !important;
}
.icon-input-role {
  .MuiAutocomplete-root {
    // padding:0.5px 4px 7.5px 5px!important
  }
}

.outlined.active {
  border-radius: 17.6px !important;
  padding: 6px 24.5px 8.1px 23px !important;
  height: 35px !important;
  box-shadow: none !important;
  text-transform: inherit !important;
  background-color: #c41035 !important;
  color: white !important;
}

.card-menu-style {
  // padding-top: 30px !important;
  // padding-bottom: 30px !important;
  // padding-right: 10px;
  // padding-left: 10px;
  border-radius: 26px !important;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.04) !important;
  background-color: white !important;
  .icon-disable {
    visibility: hidden !important;
  }
  &:hover {
    background-color: rgba(186, 184, 184, 0.166) !important;
    box-shadow: none !important;
    .icon-disable {
      visibility: visible !important;
    }
  }
  &:visited {
    background-color: rgba(186, 184, 184, 0.166) !important;
  }
}

.outline {
  background-color: white !important;
  border: 1px solid var(--stored-color, #c41035) !important;
  color: var(--stored-color, #c41035) !important;
  border-radius: 32px !important;
  font-size: 14px !important;
  box-shadow: none !important;
  text-transform: inherit !important;
  padding: 5px 20px !important;
  width: 170px !important;
  // min-width: 170px !important;

  &:hover {
    background-color: var(--stored-color, #c41035) !important;
    box-shadow: none !important;
    color: white !important;
  }
}

.contained-save {
  background-color: var(--stored-color, #c41035) !important;
  border: 1px solid var(--stored-color, #c41035) !important;
  color: white !important;
  border-radius: 32px !important;
  font-size: 14px !important;
  box-shadow: none !important;
  text-transform: inherit !important;
  padding: 5px 20px !important;
  // min-width: 170px !important;

  &:hover {
    background-color: var(--stored-color, #c41035) !important;
    box-shadow: none !important;
    color: white !important;
  }
}

.contained-card {
  background-color: var(--stored-color, #c41035) !important;
  border-radius: 32px !important;
  font-size: 14px !important;
  box-shadow: none !important;
  text-transform: inherit !important;
  color: white !important;
  padding: 0px !important;
  // min-width: 110px!important;
  // min-width: 170px !important;
  color: white !important;

  &:hover {
    background-color: var(--stored-color, #c41035) !important;
    box-shadow: none !important;
  }
}

.generate-key {
  background-color: #10bf36 !important;
  border-radius: 32px !important;
  font-size: 13px !important;
  box-shadow: none !important;
  text-transform: inherit !important;
  padding: 4px 10px !important;
  color: #f7f7f7 !important;

  &:hover {
    background-color: #0c8f28 !important;
    box-shadow: none !important;
  }
}
.add-rate {
  background-color: #10abbf !important;
  border-radius: 32px !important;
  font-size: 13px !important;
  box-shadow: none !important;
  text-transform: inherit !important;
  padding: 4px 10px !important;
  color: #f7f7f7 !important;

  &:hover {
    background-color: #108c9c !important;
    box-shadow: none !important;
  }
}
.confirmation-btn {
  width: 80%;
  height: 50px !important;
  border-radius: 30px !important;
  background-color: var(--stored-color, #c41035) !important;
  box-shadow: none !important;
  color: white !important;
  font-size: 18px !important;
  font-weight: 700;
  text-transform: inherit !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;

  &:hover {
    background-color: var(--stored-color, #c41035) !important;
    box-shadow: none !important;
  }
}
.confirmation-btn-snack {
  width: 100%;
  height: 50px !important;
  border-radius: 30px !important;
  background-color: var(--stored-color, grey) !important;
  box-shadow: none !important;
  color: white !important;
  font-size: 18px !important;
  font-weight: 700;
  text-transform: inherit !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;

  &:hover {
    background-color: var(--stored-color, grey) !important;
    box-shadow: none !important;
  }
}

.BreadcrumbsPage {
  font-size: 18px !important;
  font-weight: bold !important;
  color: var(--color, black) !important;
}

.breadcrumbactiveBtn {
  font-size: 18px !important;
  font-weight: bold !important;
  color: var(--stored-color, #c41035) !important;

  &:hover {
    text-decoration: none !important;
  }
}

.MuiLink-underlineHover {
  text-decoration: none !important;
}

.MuiBreadcrumbs-separator {
  font-size: 18px !important;
  font-weight: bold !important;
  color: black !important;
}

.textfield-form-style {
  border: "15px !important";
  height: "40px !important";
  border: "1px solid #e0dede !important";

  @media screen and (max-width: 560px) {
    .vertical-tabs {
      width: 100% !important;
      margin-bottom: 20px;
      // margin-right: 10%;
    }

    .tabs-name {
      font-size: 10px !important;
      text-align: left !important;
      // -webkit-box-align:start !important
    }

    .main-box {
      margin-left: 0px !important;
      margin-right: 0px !important;
    }

    .centerresponsive {
      justify-content: center !important;
    }

    .endresponsive {
      justify-content: center !important;
    }
  }

  .css-bvyohc-MuiTypography-root-MuiLink-root:hover {
    text-decoration: none !important;
  }
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px white;
  border-radius: 10px 10px 10px 10px;
}

::-webkit-scrollbar-thumb {
  background: #edb5b5;
  border-radius: 10px 10px 10px 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #dba4a4;
}

.Grid-filter-btn {
  margin-top: 140%;

  @media screen and (max-width: 1610px) {
    margin-top: 70%;
  }
}

.icon-box-close {
  top: 65px;
  left: 0px;
  cursor: pointer;
  position: absolute;
  color: rgba(0, 0, 0, 0.54);
  // background-color: red;
  border-radius: 50%;
  padding: 5px;

  &:hover {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.04);
    border-radius: 50%;
    padding: 5px;
  }
}

.vertical-tabs-page {
  .MuiTabs-flexContainer {
    margin-bottom: 20px;
  }
  .MuiTab-root.Mui-selected {
    background-color: var(--stored-color, #c41035) !important;
    border: none !important;
    border-radius: 25px 0 0 25px;
    color: white !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    // box-shadow: none !important;
    text-transform: inherit;
    min-height: 43px !important;
    padding: 6px 16px !important;
    filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.3)) !important;
    box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.3) !important;
  }

  .MuiTabs-indicator {
    display: none !important;
  }
}

.vertical-tabs-responsive {
  background: white !important;
  border-radius: 25px 0px 0px 25px !important;
  font-size: "16px" !important;
  font-weight: "400" !important;
  text-transform: "inherit";
  min-width: "116px";
  color: "#949494" !important;
  min-height: "43px";
  padding: "6px 16px";

  // @media (max-width: 570px) {
  //   width: 116px !important;
  //   padding: 0px !important;
  //   font-size: 12px !important;
  // }
}

.div-table {
  position: relative;

  .vertical-tabs {
    // margin-left: 28px;
    // justify-content: end;
    margin-top: 45px;
  }

  @media screen and (max-width: 1536px) {
    .vertical-tabs {
      margin-left: 3px;
    }
  }
}

.Scorllbar-tabs-hor {
  border-top-right-radius: 20px !important;
  border-top-left-radius: 20px !important;
}

.horizantal-tabs-page {
  // margin-bottom: -3px;
  // margin-bottom: -26px;
  // border-radius: 32px !important;
  .MuiTab-root.Mui-selected {
    background-color: var(--stored-color, #c41035) !important;
    border: none !important;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    color: white !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    box-shadow: none !important;
    text-transform: inherit;
    min-height: 43px !important;
    padding: 6px 16px !important;
    z-index: 2 !important;
  }

  .MuiTabs-indicator {
    display: none !important;
  }
}

.MuiDialog-paper {
  border-bottom-left-radius: 32px !important;
  border-bottom-right-radius: 32px !important;
}

.Scorllbar-tabs-hor {
  .MuiTabs-scrollButtons {
    display: none !important;

    @media (max-width: 1000px) {
      display: block !important;
    }
  }

  @media (max-width: 1000px) {
    margin-left: -40px;
  }
}

// .MUIpaperVertical {
//   background-color: white;
//   border-radius: 20px !important;
//   padding: 20px !important;
//   height: 100% !important;
//   padding-bottom: 5% !important;
//   box-shadow: none !important;
// }
// .MUIpaperHorizantal {
//   background-color: white;
// // border-top-right-radius: 20px !important;
// // border-bottom-left-radius: 20px !important;
//   padding: 20px !important;
//   height: 100% !important;
//   padding-bottom: 5% !important;
//   box-shadow: none !important;
// }

// .horizantal-tabs-name {
//   font-size: 12px !important;
//   text-align: start !important;
//   -webkit-align-items: start !important;
//   -webkit-box-align: start !important;
//   -webkit-box-pack: start !important;
//   -webkit-justify-content: start !important;
// }
// .tabs-name {
//   font-size: 12px !important;
//   text-align: start !important;
//   -webkit-align-items: start !important;
//   -webkit-box-align: start !important;
//   -webkit-box-pack: start !important;
//   -webkit-justify-content: start !important;
// }

// .tabs-name {
//   &:hover {
//     background-color: var(--stored-color, #c41035) !important;
//     color: white;
//   }
// }

// .horizantal-tabs-name {
//   &:hover {
//     background-color: var(--stored-color, #c41035) !important;
//     color: white;
//   }
// }

@media (max-width: 599.95px) {
  .MuiTabs-scrollButtons {
    display: block !important;
  }
}

.MuiTypography-root {
  font-family: "AvertaStd-Regular" !important;
}

.label-card {
  font-size: 18px !important;
  font-weight: bold !important;
  color: var(--stored-color, #c41035) !important;
  display: flex !important;

  &:hover {
    text-decoration: none !important;
  }
}

.label-card-2 {
  font-size: 14px !important;
  font-weight: bold !important;
  color: var(--stored-color, #c41035) !important;
  display: flex !important;

  &:hover {
    text-decoration: none !important;
  }
}

.sub-label-card {
  font-size: 14px !important;
  font-weight: bold !important;
  color: black !important;
  display: flex !important;

  &:hover {
    text-decoration: none !important;
  }
}

.sub-label-card-2 {
  font-size: 14px !important;
  color: black !important;
  display: flex !important;
  margin-left: 2px !important;

  &:hover {
    text-decoration: none !important;
  }
}

.channel-card {
  box-shadow: none !important;
  border-radius: 12px !important;
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
    opacity: 1;
    transition: opacity 0.3s ease;
  }
}

.text-card-channel {
  font-weight: bold !important;
}

.icon-channel-card {
  margin-top: 30px;
  position: absolute;
  color: var(--stored-color, #c41035) !important;
  font-weight: 200 !important;
  font-size: 130px !important;
}

// .card-content-channel {
//   padding: 20% !important;
// }

.client-icons {
  color: var(--stored-color, #c41035) !important;
  font-size: 50px !important;
}

.dashboard-icons {
  color: white !important;
  font-size: 50px !important;
}

.clients-data {
  font-weight: bold;
  color: var(--stored-color, #c41035) !important;
  font-size: 20px !important;
}

.manage-client-transform {
  color: var(--stored-color, #c41035) !important;
  font-weight: bold;
  margin: 5px;
  transform: scale(-1);
  display: flex;
}

.manage-client-transform-open {
  color: var(--stored-color, #c41035) !important;
  font-weight: bold;
  margin: 5px;
  transform: scale(1);
  display: flex;
}

.paper-channel {
  border-radius: 32px !important;
  background-color: white !important;
  padding: 20px;
  box-shadow: none !important;
}

.paper-channel-box {
  border-radius: 32px !important;
  background-color: white !important;
  padding: 20px;
  box-shadow: "0px 4px 8px rgba(0, 0, 0, 0.2)";
}

.icon-color-primary {
  color: #c41035;
}

.ChannelTabs-page {
  margin-right: 25px !important;

  .MuiTab-root.Mui-selected {
    background-color: #c41035;
    // border: 1px solid #949494;
    border: none !important;
    border-radius: 25px;
    color: white !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    box-shadow: none !important;
    text-transform: inherit;
    min-height: 43px !important;
    padding: 6px 16px !important;
    text-align: start !important;
    // -webkit-align-items: start !important;
    -webkit-box-align: start !important;
    -webkit-box-pack: start !important;
    -webkit-justify-content: start !important;
  }

  .MuiTabs-indicator {
    display: none !important;
  }
}

.MuiMenuItem-root {
  &:hover {
    background-color: transparent !important;
  }
}

.charts-title {
  font-weight: bold !important;
  color: black !important;
  margin-bottom: 20px !important;
  font-size: 20px !important;
  // justify-content: center;
  // display: flex;
  // width: 100%;
}

.ChannelTabs-page-mobile {
  margin-right: 25px !important;

  #vertical-tab-0 {
    display: none !important;
  }

  .MuiTab-root.Mui-selected {
    // background-color: #c41035;
    border: 1px solid #c41035;
    // border: none !important;
    border-radius: 25px;
    color: #c41035 !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    box-shadow: none !important;
    text-transform: inherit;
    min-height: 43px !important;
    padding: 6px 16px !important;
    text-align: start !important;
    // -webkit-align-items: start !important;
    -webkit-box-align: start !important;
    -webkit-box-pack: start !important;
    -webkit-justify-content: start !important;
  }

  .MuiTabs-indicator {
    display: none !important;
  }
}

.top-resellers-nb {
  font-size: 25px !important;
  color: white !important;
  text-align: center;
  // margin-left: 30px !important;
  background-color: var(--stored-color, #c41035) !important;
  border-radius: 40px !important;
  height: 40px !important;
  width: 40px !important;
  margin-top: -5px !important;
}

.reseller-name {
  color: var(--stored-color, #c41035) !important;
  font-size: 20px !important;
  font-weight: bold !important;
}

.reseller-count {
  font-size: 18px;
  margin-top: -5px !important;
  margin-left: 0px !important;
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    color: var(--stored-color, #c41035) !important;
  }
}

.info-arrow {
  cursor: pointer;
}

.top-reseller-container {
  .number-grid {
    background-color: var(--stored-color, #c41035) !important;
    border-radius: 40px !important;
    height: 50px !important;
    width: 50px !important;
  }

  .top-reseller-container {
    padding-bottom: 20px;
    text-align: center;
  }
}

.main-card {
  padding: 20px;
  border-radius: 20px !important;
}

.kpi-card {
  margin-top: 20px;
  padding: 20px;
  border-radius: 20px !important;
}

.kpi-card-reseller {
  margin-top: 20px;
  padding: 20px;
  border-radius: 20px !important;

  @media (max-width: 1600px) {
    height: 380px !important;
  }
}

.top-5-row {
  margin-left: 20px !important;
  margin-top: 3px !important;

  @media (max-width: 1600px) {
    margin-top: -20px !important;
  }
}

.top-5-card {
  padding: 15px;
  border-radius: 20px !important;
  margin-top: 20px;
  margin-left: 10px;
}

.kpi-title {
  font-size: 25px !important;
  display: flex;
  justify-content: center;
  width: 100%;
  font-weight: bold !important;
  color: black;
}

.kpi-data {
  font-size: 18px !important;
  color: var(--stored-color, #c41035) !important;
  display: flex !important;
  justify-content: center !important;
  width: 100%;
  margin-top: 10px !important;
}

.eye-icon {
  cursor: pointer !important;
}

.revenue {
  background-color: var(--stored-color, #c41035) !important;
  width: 50px;
  font-size: 11px !important;
  color: white;
  padding: 2px;
  border-radius: 23px;
  text-align: center;
}

.reseller-btns-row {
  margin-top: 10px;
  margin-left: 25px;
}

.reseller-card {
  border-radius: 20px !important;
  height: 50px !important;
  padding: 10px;
  background-color: rgb(234, 234, 232) !important;
  width: 155px !important;
  box-shadow: none !important;

  @media (max-width: 899px) {
    width: auto !important;
  }
}

.channel-card {
  border-radius: 20px !important;
  height: 50px !important;
  padding: 10px;
  background-color: rgb(234, 234, 232) !important;
  width: 100% !important;
  box-shadow: none !important;
}

.reseller-card-name {
  font-size: 18px !important;
  font-weight: bold !important;
  color: #757575;
}

.service-card-name {
  font-size: 14px !important;
  margin-top: 5px !important;
  color: #757575;
}

.reseller-card-icon {
  margin-top: -100px !important;
  font-size: 35px !important;
  color: #757575;
}

.reseller-card-icon-client {
  margin-top: 5px !important;
  font-size: 35px !important;
  color: #757575;
}

.col-border {
  border-right: 1px solid var(--stored-color, #c41035);

  @media (max-width: 900px) {
    border-right: none;
  }
}

.channel-tabs-style-name {
  -webkit-box-align: start !important;
  -webkit-box-pack: start !important;
  -webkit-justify-content: start !important;
}

.smschart-page {
  .MuiBox-root {
    height: auto !important;
  }
}

@media screen and (max-width: 500px) {
  .breadcrumbactiveBtn {
    font-size: 14px !important;
  }

  .BreadcrumbsPage {
    font-size: 14px !important;
  }
}

@media screen and (max-width: 724px) {
  .breadcrumbactiveBtn {
    font-size: 14px !important;
  }

  .BreadcrumbsPage {
    font-size: 14px !important;
  }

  .icon-color-primary {
    font-size: 19px !important;
  }

  .title-chart {
    font-size: 15px !important;
  }
}

.horizantal-channel-tab {
  .MuiButtonBase-root-MuiTab-root {
  }
  // position: absolute;
  .MuiTab-root.Mui-selected {
    background-color: #c41035;
    // border: 1px solid #c41035;
    // border: none !important;
    border-radius: 25px;
    color: white !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    box-shadow: none !important;
    text-transform: inherit;
    min-height: 35px !important;
    z-index: 2 !important;
    // padding: 6px 16px !important;
    // text-align: start !important;
    // -webkit-align-items: start !important;
    // -webkit-box-align: start !important;
    // -webkit-box-pack: start !important;
    // -webkit-justify-content: start !important;
    .chanel-icons {
      color: white !important;
    }
  }

  .MuiTabs-indicator {
    display: none !important;
  }
}
.horizantal-tab {
  .MuiButtonBase-root-MuiTab-root {
  }
  // position: absolute;
  .MuiTab-root.Mui-selected {
    background-color: #c41035;
    // border: 1px solid #c41035;
    // border: none !important;
    border-radius: 10px 10px 0px 0px;
    color: white !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    box-shadow: none !important;
    text-transform: inherit;
    min-height: 35px !important;
    z-index: 2 !important;
    // padding: 6px 16px !important;
    // text-align: start !important;
    // -webkit-align-items: start !important;
    // -webkit-box-align: start !important;
    // -webkit-box-pack: start !important;
    // -webkit-justify-content: start !important;
    .chanel-icons {
      color: white !important;
    }
  }

  .MuiTabs-indicator {
    display: none !important;
  }
}
.client-data-nb {
  font-size: 18px !important;
  align-items: center;
  display: flex;
  flex-direction: row;
  color: #757575 !important;
  // margin-top: 10px !important;
  cursor: pointer;

  // text-decoration: underline;
  &:hover {
    color: #c41035 !important;
  }
}

.icon-color-dashb-client {
  // font-size: 18px !important;
  // align-items: center;
  // display: flex;
  // flex-direction: row;
  color: #757575 !important;
  // margin-top: 10px !important;
  cursor: pointer;

  // text-decoration: underline;
  &:hover {
    color: #c41035 !important;
  }
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 5px 10px !important;
}

.dashboard-dialog {
  .MuiDialog-paper {
    border-radius: 23px !important;
  }

  .MuiDialogTitle-root + .css-ypiqx9-MuiDialogContent-root {
    max-height: 250px !important;
    padding: 20px;
  }
}

.btns {
  height: 35px !important;
}

.custom-select {
  border: none !important;
}

@media (max-width: 500px) {
  .top-space-mobile {
    margin-top: 10px !important;
  }
}

.slick-prev:before,
.slick-next:before {
  color: #757575 !important;
}

.reseller-card-name {
  font-size: 18px !important;
  font-weight: bold !important;
}

.reserved-name {
  font-size: 16px !important;
  font-weight: bold !important;
}

.code-title {
  font-size: 22px !important;
  color: var(--stored-color, #c41035) !important;
  font-weight: bold !important;
  margin-bottom: 10px !important;
}

.dropzone {
  border: 2px dashed var(--stored-color, #c41035) !important;
  padding: 20px !important;
  width: 100%;
}

.import-icon {
  font-size: 60px !important;
  color: var(--stored-color, #c41035) !important;
  cursor: pointer;
}

.save-import {
  border-radius: 23px !important;
  margin-top: 20px !important;
}

.code-icon {
  font-size: 70px !important;
  color: var(--stored-color, #c41035) !important;
  margin-left: 20px !important;
}

// .MuiInputBase-input {
//   cursor: pointer;
// }

// .css-1ufn0jl{
//   border-radius: 32px !important;
// }

// .css-12i7wg6-MuiPaper-root-MuiDrawer-paper {
//   background-color: var(--backgroundColor, #fff) !important;
// }

// .MuiTypography-root {
//   color: var(--color, #fff) !important;
//   ;
// }

// .css-1160xiw-MuiPaper-root-MuiDrawer-paper {
//   background-color: var(--bodybackgroundColor, #fff) !important;
// }

// .css-3xneo6-MuiButtonBase-root-MuiTab-root {
//   background-color: var(--backgroundColor, #fff) !important;
// }

// .css-8wxwq2-MuiButtonBase-root-MuiTab-root {
//   background-color: var(--backgroundColor, #fff) !important;
// }

// .contained {
//   .MuiTypography-root {
//     color: #fff !important;
//     ;
//   }
// }

// .outline {
//   .MuiTypography-root {
//     color: #c41035 !important;
//     &:hover{
//       color: white !important;
//     }
//   }
// }

// .css-wa5fuu-MuiListItemIcon-root {
//   color: var(--color, #fff) !important;
// }

// .css-i4bv87-MuiSvgIcon-root {
//   color: #c41035 !important;
// }

// .css-5a8y9g-MuiPaper-root {
//   background-color: var(--backgroundColor, #fff) !important;
// }

// .css-1tkih2-MuiPaper-root {
//   background-color: var(--backgroundColor, #fff) !important;
// }

// .css-g98r-MuiPaper-root {
//   background-color: var(--backgroundColor, #fff) !important;
// }

// .css-35twke-MuiDataGrid-root .MuiDataGrid-cellContent {
//   color: var(--color, #fff) !important;
// }

// .css-ptiqhd-MuiSvgIcon-root {
//   color: var(--color, #fff) !important;
// }

// .css-1ua80n0-MuiInputBase-input-MuiOutlinedInput-input {
//   color: var(--color, #fff) !important;
// }

// .css-levciy-MuiTablePagination-displayedRows {
//   color: var(--color, #fff) !important;
// }

// .css-35twke-MuiDataGrid-root {
//   border: none !important;
// }

// .css-b399nd-MuiPaper-root-MuiAppBar-root {
//   background-color: var(--backgroundColor, #fff) !important;
// }

// .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
//   color: var(--color, #fff) !important;
// }

// .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
//   color: var(--color, #fff) !important;

// }

// .css-twia2z-MuiPaper-root-MuiDialog-paper {
//   background-color: var(--bodybackgroundColor, #fff) !important;
// }

// .css-5z90co-MuiPaper-root-MuiAppBar-root {
//   background-color: var(--bodybackgroundColor, #fff) !important;
// }

// .user-login {
//   color: white !important;

// }

.css-1nyzfp7 {
  border: none !important;
}

.css-oscfek {
  border: none !important;
}

.css-1b9xoz0 {
  border: none !important;
}

.css-1x5jdmq {
  padding: 4.5px 4px !important;
}

.css-1v4ccyo {
  border-radius: 32px !important;
}

.css-1ufn0jl {
  border-radius: 32px !important;
}

.css-le0j7x {
  border-radius: 32px !important;
}

.MuiIconButton-root {
  &:hover {
    background-color: transparent !important;
  }
}

// .css-jm6a43{
//   background-color: var(--backgroundColor, #fff) !important;
// }

// .css-1rj0wkl-MuiPaper-root{
//   background-color: var(--backgroundColor, #fff) !important;
// }

// .css-bhp9pd-MuiPaper-root-MuiCard-root{
//   background-color: var(--backgroundColor, #fff) !important;
// }

// .css-tgvgw8-MuiPaper-root-MuiCard-root{
//   background-color: var(--backgroundColor, #fff) !important;
// }

.import-table {
  width: 130px !important;
  margin-right: 10px !important;
  justify-content: left;
  display: flex;
  @media (max-width: 570px) {
    width: 100px !important;
  }
}

.import-table-responsive {
  margin-left: 5px !important;
  margin-top: 25px !important;
  padding: 7px !important;
  padding-left: 9px !important;

  &.css-1w1rijm-MuiButtonBase-root-MuiButton-root {
    min-width: 25px !important;
  }
}

.billing {
  @media (max-width: 730px) {
    width: 75vw !important;
    margin-left: 15px;
  }
}

.hidden-btn {
  visibility: hidden !important;
}
.MuiSwitch-root .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
  background-color: #c4103585 !important;
}
.MuiSwitch-thumb {
  background-color: #ca2a4b !important;
}

.draggable-div {
  position: absolute;
  // border: 1px solid #ccc!important;
  padding: 10px !important;
  z-index: 820;
}

.handle {
  cursor: grab !important;
  // background-color: #ddd!important;
  padding: 5px !important;
  margin-bottom: 5px !important;
  z-index: 820;
}
.MuiPaginationItem-root.Mui-selected {
  background-color: #c41035 !important;
}
.MuiDataGrid-columnHeaders {
  // background-color: #c41035 !important;
  padding-bottom: 2px !important;
  border-radius: 12px 12px 0px 0px !important;
}
.MuiButtonBase-root-MuiCheckbox-root:first-child {
  color: red !important;
}
.selectLoad {
  background: red;
}
.icon-member {
  font-size: 1.9rem;
  color: #c41035;
}

.nav-item {
  padding: 1px !important;
  .MuiTypography-root {
    font-size: 13px !important;
  }
  @media (max-width: 600px) {
    padding: 5px !important;
  }

  & .list.item {
    border-radius: 5rem !important;
    color: #717171 !important;
    font-size: 12px !important;

    &.active,
    &:hover {
      // background-color: #fdadbdb2 !important;
      color: #c41035 !important;
      text-decoration: none !important;

      .nav-btn {
        i {
          color: #c41035 !important;
          font-size: 20px !important;
          // padding-left: 0.1rem !important;
        }
      }
    }

    .nav-btn {
      i {
        color: #c41035 !important;
        font-size: 20px;
        // padding-left: 0.1rem !important;
      }
    }
  }
}
.MuiListItemIcon-root {
  min-width: 45px !important;
}
.MuiList-root {
  overflow-x: hidden !important;
  overflow-y: auto !important;
}
.MuiInputBase-input-MuiOutlinedInput-input {
  padding: 0.5px 4px 7.5px 5px !important;
}

.MuiButtonBase-root-MuiTab-root {
  z-index: 2 !important;
}
.MuiTab-root {
  z-index: 2 !important;
}
.page-paper{
  @media (max-width: 1000px){
  padding-bottom: 50px !important;
  width: 70vw !important;
}
}
 
.page-paper-twoways{
  @media (max-width: 1000px){
    // height: 100vh !important;
    padding-bottom: 50px !important;
    width: 70vw !important;
    margin-left: 60px !important;
  }
}
 
.add-route-btn{
  @media (max-width: 1200px){
    margin-top: 15px !important;
    margin-right: 10px !important;
  }
}
 
.add-code-btn{
  @media (max-width: 1200px){
    margin-right: -100px !important;
  }
}

.MuiInputBase-input-MuiOutlinedInput-input{
  padding:0px!important
}
// .MuiOutlinedInput-input{
//   padding:5px!important
// }

// .MuiOutlinedInput-input.MuiSelect-select{
//   padding: 10px  5px 5px 8px !important;
// }

.download-sample{
  color: rgb(196, 16, 53);
  text-decoration: underline;
  text-align: end;
  width: 100%;

  &:hover{
    cursor: pointer;
  }
}