.sign-in {
  background-image: url("../Assets/SignInBack.jpg") !important;
  background-size: cover !important;
  width: 100%;
  height: 104vh !important;
  // max-height: 92vh;
  //   display: block !important;
  @media screen and (max-width: 664px) {
    // width: fit-content;
    // height: 170vh !important;
  }
}

.hide-scroll {
  margin: 0 !important;

  overflow: hidden !important;
  @media screen and (max-height: 664px) {
    overflow: auto !important ;
  }
}
.container {
  max-width: 800px !important;
}

body {
  // overflow: hidden !important;
}

.paper {
  height: 500px;
  width: 600px !important;
  background-color: rgba(183, 190, 196, 0.8) !important;
  margin-top: -500px;
  margin-left: 22.5%;
  position: relative;
  padding-top: 10px;
  z-index: 1000;
  transition: margin-left 0.5s ease !important;
  @media screen and (max-width: 1600px) {
    margin-left: 15%;
  }
  @media screen and (max-width: 1400px) {
    margin-left: 10%;
  }
  @media screen and (max-width: 1250px) {
    margin-left: 5%;
  }
  @media screen and (max-width: 1150px) {
    width: 550px !important;
    height: 520px !important;
  }
  @media (max-width: 1000px) {
    margin-top: 0px;
    margin-left: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: auto;
    // width: 100% !important;
  }

  @media screen and (max-width: 800px) {
    height: 500px;
  }
  @media screen and (max-width: 550px) {
    height: 550px;
    width: 400px !important;
  }

  @media screen and (max-width: 410px) {
    height: 550px;
    width: 350px !important;
  }
}

.transition-paper {
  height: 500px;
  width: 600px !important;
  background-color: rgba(183, 190, 196, 0.8) !important;
  margin-top: -500px;
  margin-left: 46%;
  padding-top: 10px;
  position: relative;
  z-index: 1000;
  transition: margin-left 0.5s ease !important;
  @media screen and (max-width: 1170px) {
    margin-left: 44%;
  }
  @media screen and (max-width: 1150px) {
    width: 550px !important;
    height: 520px !important;

  }
  @media (max-width: 1000px) {
    display: none;
  }
  @media screen and (max-width: 800px) {
    height: 500px;
  }
  @media screen and (max-width: 550px) {
    height: 550px;
  }
}
.transition-paper-omni {
  height: 500px;
  width: 650px !important;
  background-color: rgba(183, 190, 196, 0.8) !important;
  // margin-top: -500px;
  margin: -500px auto auto auto;
  // margin-left: 30%;
  padding-top: 10px;
  position: relative;
  z-index: 1000;
  transition: margin-left 0.5s ease !important;
  @media screen and (max-width: 1170px) {
    // margin-left: 44%;
  }
  @media screen and (max-width: 1150px) {
    width: 550px !important;
    height: 520px !important;
  }
  @media (max-width: 1000px) {
    margin: 0px auto auto auto;

    // display: none;
  }
  @media screen and (max-width: 800px) {
    height: 500px;
  }
  @media screen and (max-width: 550px) {
    width: 400px !important;
    height: 550px;
  }

  @media (max-width: 400px){
    width: 350px !important;
  }
}

.flip-paper {
  @media (max-width: 1000px) {
    transform: rotateY(180deg) !important;
    transition: transform 1s ease !important;
  }
}

.flip-paper-main {
  @media (max-width: 1000px) {
    transform: rotateY(0deg) !important;
    transition: transform 1s ease !important;
  }
}

.original-card {
  background-color: rgba(255, 11, 61, 0.28) !important;
  margin-top: 200px;
  height: 350px;
  margin-bottom: 0px !important;

  @media (max-width: 1600px) {
    margin-top: 150px;
  }

  @media (max-width: 1000px) {
    display: none !important;
  }
}
.sign-card-btn {
  margin-top: 10px !important;
  background-color: transparent !important;
  box-shadow: none !important;
  border: 1px solid white !important;
  border-radius: 23px !important;
  color: white !important;
  width: 130px !important;
}

.transition {
  opacity: 0; /* Set initial opacity */
  animation: transition-login 3s forwards ease;
}

@keyframes transition-login {
  /* Set keyframes for opacity change */
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.arrow-icon {
  position: absolute;
  margin-top: 5px;
  margin-left: 5px;
}

.arrow-icon-back {
  position: absolute;
  transform: rotate(180deg);
  margin-top: 5px;
  margin-left: -25px !important;
}

.login-omni-btn {
  cursor: pointer;
  color: white;

  @media (min-width: 1000px) and (max-width: 2000px) {
    display: none;
  }
}

.logo-style {
  margin-top: 20px;
  width: 160px;
  height: 50px;
}

.custom-grid-class {
  margin-top: 150px !important;

  @media screen and (max-width: 1600px) {
    margin-top: 50px !important;
  }
}

.login-inputs {
  padding: 8px !important;
  width: 500px;
  @media screen and (max-width: 550px) {
    width: 300px;
  }
}

.choose-env-text {
  font-size: 18px !important;
}

.login-select {
  margin-top: 20px !important;
  width: 400px;
  height: 50px;
  // border-bottom: 1px solid gray;
  border-radius: 0px !important;
  @media screen and (max-width: 790px) {
    width: 500px;
  }
  // @media screen and (max-width: 550px) {
  //     width: 310px;
  // }
  @media screen and (max-width: 550px) {
    width: 300px !important;
    margin-left: 0px;
  }
}

.login-select-small {
  margin-top: 20px !important;
  // margin-left: 20px;
  height: 50px;
  width: 400px;
  // border-bottom: 1px solid gray;
  border-radius: 0px !important;
  @media screen and (max-width: 790px) {
    width: 500px;
    margin-left: 0px;
  }
  // @media screen and (max-width: 550px) {
  //     width: 310px;
  //     margin-left: 0px;
  // }
  @media screen and (max-width: 550px) {
    width: 300px !important;
    margin-left: 0px;
  }
}

.login-select-big {
  margin-top: 20px !important;
  width: 500px;
  height: 50px;
  // border-bottom: 1px solid gray;
  border-radius: 0px !important;
  @media screen and (max-width: 550px) {
    width: 310px;
  }
}

.signin-btn {
  width: 100%;
  margin-top: 30px !important;
  padding: 8px;
  height: 50px;
  font-size: 21px !important;
  color: white !important;
  border-radius: 32px !important;
  box-shadow: none !important;
  &:hover {
    background-color: var(--stored-color, #c41035) !important;
    color: white; // Adjust the text color as needed
  }
  &.disabled {
    background-color: gray;
    color: white; // Adjust the text color as needed
    cursor: not-allowed;
  }

  &:not(.disabled) {
    background-color: var(--stored-color, #c41035) !important;
    color: white; // Adjust the text color as needed
  }

  //   background:#c41035!important ;
  // @media screen and (max-width: 550px) {
  //     width: 310px;
  // }
}

.content-box {
  padding-left: 130px !important;
  padding-right: 130px !important;
  // @media screen and (max-width: 1000px) {
  //     transform: rotateY(180deg) !important;
  //     transition: transform 1s ease !important;
  // }
  @media screen and (max-width: 680px) {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }
  @media screen and (max-width: 450px) {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
}

.content-box-flip {
  @media screen and (max-width: 1000px) {
    transform: rotateY(180deg) !important;
    transition: transform 1s ease !important;
  }
}
