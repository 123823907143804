.Landing {
  text-align: center !important;
  //  background-image: url("../../../Assets/framework-management-background.jpg") !important;
  background-size: cover !important;
  width: 100%;
  height: 104vh !important;
  overflow: hidden !important;
}
.Landing-with-img {
  text-align: center !important;
   background-image: url("../../../Assets/SignInBack.jpg") !important;
  background-size: cover !important;
  width: 100%;
  height: 104vh !important;
  overflow: hidden !important;
}
.hide-scroll {
  margin: 0 !important;

  overflow: hidden !important;
  @media screen and (max-height: 664px) {
    overflow: auto !important ;
  }
}
